body {
  color: white;
}

table {
  background: linear-gradient(180deg, #0089ff 0%, #4cc7ff 100%);
  border-radius: 0px 0px 50px 50px;
  width: 100%;
  height: 20rem;
  border: none;
  padding-bottom: 10px;
}

table tr th:first-child,
table tr td:first-child {
  color: red;
}

table tr th,
table tr td {
  border: none;
  border-collapse: collapse;
  color: white;
  text-align: center;
}

#btn {
  text-align: center;
}

#btn input {
  background-color: transparent;
  border-style: none;
  color: white;
  font-size: 40px;
}

button {
  background-color: transparent;
  border: none;
  color: white;
  font-size: 17px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
  display: flex; 
}

button span {
  flex: 1; 
  text-align: center; 
}
button.clicked {
  background-color: rgb(255, 255, 255) !important;
  color: #0089ff !important;
  text-align: center;
}

.button-clicked {
  background-color: white;
  color: #0089ff;
  border-radius: 50%;
}
